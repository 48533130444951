<template>
  <div>
    <div id="datos-solicitud">
      <v-form ref="form" v-model="valid">
        <v-main>
          <v-row class="align-center align-content-center">
            <v-col class="col-sm-12 col-md-6 col-12" align-self="center">
              <v-row>
                <v-col class="col-md-12" align-self="center">
                  <h2 class="darkPurple">¡Felicidades!</h2>
                  <p>Tu préstamo aprobado es de</p>
                  <h2 class="boldMountedText">
                    ${{ Number(amount).toLocaleString("es-AR") }}
                  </h2>
                </v-col>
              </v-row>
              <v-row
                align-content="center"
                class="stepper-register__left-section-image"
              >
                <v-img
                  src="@/assets/img/yellow_pet_happy.svg"
                  alt="logo"
                  contain
                  max-height="500"
                  min-height="500"
                  position="center center"
                />
              </v-row>
            </v-col>

            <v-col
              class="
                col-md-6
                align-self-center
                col-sm-12
                quotation__left-section--margin
              "
            >
              <p class="botonBajaDesktop">¿Cómo querés devolverlo?</p>
              <v-row v-if="plans_test">
                <v-col v-if="plans_test[1]" class="col-md-4 text-center col-4">
                  <v-chip
                    :color="plan_text === 'min' ? '#FFF000' : '#FFFFFF'"
                    large
                    class="shadowChip"
                    style=""
                    @click="selectPlan(1)"
                  >
                    <div
                      align="center"
                      justify="center"
                      class="marginBottomChip text-center"
                    >
                      <h5 class="boldMountedText marginBottomChip">
                        {{ plans_test[1].installments }} cuotas
                      </h5>
                      <p>
                        de ${{
                          Number(
                            plans_test[1].credit.avgInstallment
                          ).toLocaleString("es-AR")
                        }}
                      </p>
                    </div>
                  </v-chip>
                </v-col>

                <v-col v-if="plans_test[2]" class="col-md-4 text-center col-4">
                  <v-chip
                    :color="plan_text === 'middle' ? '#FFF000' : '#FFFFFF'"
                    large
                    class="shadowChip text-center"
                    @click="selectPlan(2)"
                  >
                    <div
                      align="center"
                      justify="center"
                      class="marginBottomChip"
                    >
                      <h5 class="boldMountedText marginBottomChip">
                        {{ plans_test[2].installments }} cuotas
                      </h5>
                      <p>
                        de ${{
                          Number(
                            plans_test[2].credit.avgInstallment
                          ).toLocaleString("es-AR")
                        }}
                      </p>
                    </div>
                  </v-chip>
                </v-col>

                <v-col v-if="plans_test[3]" class="col-md-4 text-center col-4">
                  <v-chip
                    :color="plan_text === 'max' ? '#FFF000' : '#FFFFFF'"
                    large
                    class="shadowChip text-center"
                    @click="selectPlan(3)"
                  >
                    <div
                      align="center"
                      justify="center"
                      class="marginBottomChip"
                    >
                      <h5 class="boldMountedText marginBottomChip">
                        {{ plans_test[3].installments }} cuotas
                      </h5>
                      <p>
                        de ${{
                          Number(
                            plans_test[3].credit.avgInstallment
                          ).toLocaleString("es-AR")
                        }}
                      </p>
                    </div>
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center" justify="center">
                  <v-card class="">
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <td>Plazo de devolucion</td>
                          <td class="inputOTP">
                            {{ plan_select.installments }} meses
                          </td>
                        </tr>
                        <tr>
                          <td>Monto de cada cuota</td>
                          <td class="inputOTP">
                            {{ plan_select.installments }} de <br />
                            $
                            {{
                              Number(
                                plan_select.credit.avgInstallment
                              ).toLocaleString("es-AR")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Tasa Nominal Anual (TNA)</td>
                          <td class="inputOTP">{{ interestRate }}%</td>
                        </tr>
                        <tr>
                          <td>Costo Financiero Total (CFTEA con IVA)</td>
                          <td class="inputOTP">
                            {{ plan_select.credit.cft }}%
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <v-toolbar-title class="subheading footerMounted">
                      COMENZÁ A PAGAR EN {{ payment_month }}
                    </v-toolbar-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="quotation__btn--alignment">
              <v-btn
                depressed
                rounded
                x-large
                class="btnAmarillo"
                @click="next()"
              >
                Confirmar
              </v-btn>
            </v-col>
          </v-row>
        </v-main>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AutogestionDatosCotizacion",
  data: () => ({
    valid: true,
    plan_select: {
      installments: null,
      amount: null,
      credit: {
        avgInstallment: null,
        amountInterests: null,
        amountAdminCost: null,
        amountIva: null,
        amountInsurance: null,
        amountStampTax: null,
        cft: null,
      },
    },
    interestRate: null,
    plan_text: "middle",
    formDue: {
      amount: "",
      installment: "",
      date: "",
      avgInstallment: "",
      loanId: "",
    },
    plans_test: null,
    expiration_day: "",
    payment_month: "",
    payment_year: "",
    credit: "",
  }),
  computed: {
    amount() {
      return this.$store.getters.getLoanAmount;
    },
  },
  mounted() {
    this.plans();
    console.log("Componente Datos Cotizacion creado");
  },
  methods: {
    ...mapActions(["openLoader", "closeLoader"]),
    back() {
      this.$emit("send-message", 2);
    },
    next() {
      let availableUser = this.$store.getters.getAvailable;
      if (availableUser === 0) {
        this.$router.replace({ name: "request_failed" });
        return;
      }
      if (this.plan_select.installments === null) {
        return;
      }
      let infoCredit = {
        credit: this.credit,
        monthlyExpirationDay: this.expiration_day,
      };
      this.$gtm.trackEvent({
        noninteraction: false,
        event: "WEB_ARG_CREDIT_INFO",
        id_person: this.$store.getters.getUserForm,
      });
      this.openLoader();
      this.$apiserver
        .saveCredit(infoCredit, this.plan_select)
        .then((res) => {
          if (res.message) {
            this.formDue.loanId = Number(res.message);
          }
          this.formDue.amount = this.plan_select.amount;
          this.formDue.installment = this.plan_select.installments;
          this.formDue.avgInstallment = this.plan_select.credit.avgInstallment;

          this.$store.commit("SET_LOAN", this.formDue);
          this.$emit("send-message", "3");
        })
        .catch((error) => {
          this.$store.commit("ERROR_MODAL", true);
          console.warn(error.message);
        })
        .finally(() => this.closeLoader());
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    selectPlan(index) {
      let select = null;
      if (this.plans_test === null) {
        return false;
      }
      switch (index) {
        case 1:
          select = this.plans_test[1];
          this.plan_text = "min";
          break;
        case 2:
          select = this.plans_test[2];
          this.plan_text = "middle";
          break;
        case 3:
          select = this.plans_test[3];
          this.plan_text = "max";
          break;
      }
      if (select) {
        this.plan_select = select;
      }
    },
    updateFirstPayment(firstPaymentCuttingDay) {
      let today = moment();
      let dayDate = today.date();
      let firstPayment = null;
      this.payment_month = today.add(1, "month").format("MMMM").toUpperCase();
      this.payment_year = today.year();
      if (dayDate >= firstPaymentCuttingDay) {
        firstPayment = today.add(1, "month");
        this.payment_month = firstPayment.format("MMMM").toUpperCase();
        this.payment_year = firstPayment.year();
      }
    },

    plans() {
      if (this.plans_test !== null) {
        return this.plans_test;
      }
      let anAmount = this.$store.getters.getLoanAmount;
      let installmentSelected = this.$store.getters.getLoanInstallment;
      this.openLoader();
      let listPlans = this.$apiserver.getCredits(installmentSelected, anAmount);
      listPlans
        .then((res) => {
          this.plans_test = res.plans;
          this.expiration_day = res.monthlyExpirationDay;
          this.credit = res.credit;
          this.interestRate = res.interestRate;
          this.updateFirstPayment(res.firstPaymentCuttingDay);
          this.selectPlan(2);
          this.closeLoader();
        })
        .catch((e) => {
          this.$store.commit("ERROR_MODAL", true);
          console.log(e);
          this.plans_test = null;
          this.closeLoader();
        });
    },
  },
};
</script>
